import { createChatBotMessage } from "react-chatbot-kit";

class ActionProvider {
  constructor(createChatBotMessage, setStateFunc) {
    this.createChatBotMessage = createChatBotMessage;
    this.setState = setStateFunc;
  }

  handleContactInfo = () => {
    const message = this.createChatBotMessage(
      "If you need to speak to a real person, you can call 67 03 00 00.",
      {
        loading: true,
        terminateLoading: true,
        withAvatar: true,
      }
    );

    this.addMessageToState(message);
  };
  handleManageParking = () => {
    const message = this.createChatBotMessage(
      "Here's a link to Avinors manage parking pages.",
      {
        widget: "manageParkingLink",
        loading: true,
        terminateLoading: true,
        withAvatar: true,
      }
    );

    this.addMessageToState(message);
  };

  handleLostLuggage = () => {
    const message = this.createChatBotMessage(
      "Here's a link to Avinors lost luggage pages.",
      {
        widget: "lostLuggageLink",
        loading: true,
        terminateLoading: true,
        withAvatar: true,
      }
    );

    this.addMessageToState(message);
  };
  
  handleGameSelect = (gameId) => {
    const message = this.createChatBotMessage(
      "Get our 3 best player props bets now for only - $1.99",
      {
        widget: "paymentOptions",
        loading: true,
        terminateLoading: true,
        withAvatar: true,
      }
    );
    
    this.addMessageToState(message);
    this.addIdState(gameId)
  };
  
  showBets = (id)=>{
    const message1 = createChatBotMessage(`Payment Successfull`);
    this.addMessageToState(message1);
    const message2 = createChatBotMessage(`Here are our 3 best bets`);
    this.addMessageToState(message2);
    
    let bets = [];//['Prescott - Dallas - Passing UNDER 249.5','Dowdle - Dallas - Rushing UNDER 47.5','Nabers - NY - Receiving OVER 72.5']
    
    /*if(id === 'wmHS-yfqa-MnFl'){
      bets = ['Prescott - DAL - Passing Yards UNDER 244.5','Burdy - SF - Passing Yards OVER 253.5','Dowdle - DAL - Rushing Yards OVER 45.5'];
    }
    else if(id === 'Dc8z-yhba-8mxX'){
      bets = ['GOFF - DET- Passing - OVER 240.5','WILLIAMS J - DET - Receiving - OVER 42.5','MONTGOMERY - DET - Rushing - OVER 63.5'];
    }*/
    bets = ['Mahomes - KC - UNDER 249.5 Passing Yards','Kelce - KC - UNDER 61.5 Receiving Yards','Mayfield - TB - OVER 236.5 Passing Yards'];
    
    bets.forEach((bet)=>{
      const message3 = createChatBotMessage(bet);
      this.addMessageToState(message3);
    })
  }

  handleBookParking = () => {
    const message = this.createChatBotMessage(
      "Here's a link to Avinors book parking pages.",
      {
        widget: "bookParkingLink",
        loading: true,
        terminateLoading: true,
        withAvatar: true,
      }
    );

    this.addMessageToState(message);
  };

  handleAirport = () => {
    const message = this.createChatBotMessage(
      "Do you need to switch airport?",
      {
        widget: "airportSelector",
        loading: true,
        terminateLoading: true,
      }
    );

    this.addMessageToState(message);
  };
  handleInput = async (options) => {
    const message = this.createChatBotMessage(
      "Please select option.",
    );
    
    this.addMessageToState(message);
  }

  handleOptions = async (options) => {
    const message = this.createChatBotMessage(
      "Please select a game.",
      {
        widget: "gamesOptions",
        loading: true,
        terminateLoading: true,
        ...options,
      }
    );

    this.addMessageToState(message);
  };

  handleFlightsChoice = () => {
    const message = this.createChatBotMessage(
      "Awesome. I just need a little more information",
      {
        widget: "flightSelector",
        withAvatar: true,
        loading: true,
        terminateLoading: true,
      }
    );
    this.addMessageToState(message);
  };

  handleFlightTypeChoice = (type) => {
    const message = this.createChatBotMessage(
      `Thanks. I'll retrieve the next 5 ${type} flights from your chosen airport. If you are searching for a specific flight, try typing in the flightID? (example: SK1423)`,
      {
        loading: true,
        terminateLoading: true,
        withAvatar: true,
        widget: "flightList",
      }
    );

    this.addMessageToState(message);
  };

  handleFlightIdMatch = (flightId) => {
    const message = this.createChatBotMessage(
      `Here's what I found for this flight.`,
      {
        loading: true,
        terminateLoading: true,
        withAvatar: true,
        widget: "singleFlight",
      }
    );

    this.setState((state) => ({
      ...state,
      selectedFlightId: flightId[0],
      messages: [...state.messages, message],
    }));
  };

  handleFlightNotFound = () => {
    const message = this.createChatBotMessage(
      `Sorry, couldn't find anything for that flight.`,
      {
        loading: true,
        terminateLoading: true,
      }
    );

    this.addMessageToState(message);
  };

  handleParkingOptions = () => {
    const message = this.createChatBotMessage(
      `How can I help you with parking?`,
      {
        loading: true,
        terminateLoading: true,
        withAvatar: true,
        widget: "parkingOptions",
      }
    );

    this.addMessageToState(message);
  };

  addMessageToState = (message) => {
    this.setState((state) => ({
      ...state,
      messages: [...state.messages, message],
    }));
  };
  addIdState = (value) => {
    this.setState((state) => ({
      ...state,
      'id': value
    }));
  }
}

export default ActionProvider;
