const baseURL = "https://atsl2nsysg.execute-api.eu-north-1.amazonaws.com";
const environment = "dev";
const moment  = require('moment');

export const getFlightsData = async (iata, direction) => {
  let data = await fetch(
    `${baseURL}/${environment}/flights?iata=${iata}&direction=${direction}`
  );
  data = await data.json();
  return data.flights.Flights;
};

export const getGamesData = async (type='nba') => {
  try {
    const game = type === 'nba' ? 'basketball_nba' : 'americanfootball_nfl';
    let response = await fetch(
      `https://us-central1-livelock-92cfa.cloudfunctions.net/api/api/categories/the-odds?game=${game}`
    );
    response = await response.json();
    let games = response.games;
    if(type === 'nfl'){
      const today = moment().format('YYYY-MM-DD'); // Format today's date
      const tomorrow = moment().add(1, 'day').format('YYYY-MM-DD');
      
      /*games = games.filter((item) => {
        return item.startTime.includes(today) || item.startTime.includes(tomorrow); // Checks if itemDate is the same as today
      });*/
      
      const _games = games.filter((game)=> game.id === '2Rq8-yhba-UtBf')
      //games = games.filter((game)=> game.id !== '2Rq8-yhba-UtBf')
      games = [..._games];
    }
    
    /*if(type === 'nfl'){
      return response.games.filter((game)=> game.id === 'wmHS-yfqa-MnFl')
    }*/
    return games;
  }catch (e) {
    console.log('e',e)
  }
};
export const geBetsData = async (id) => {
  try {
    let response = await fetch(
      `https://us-central1-livelock-92cfa.cloudfunctions.net/api/api/categories/get-bets/${id}`
    );
    response = await response.json();
    return response.bets;
  }catch (e) {
    console.log('e',e)
  }
};
