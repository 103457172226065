import React, { useState } from "react";
import config from "./config";
import ActionProvider from "./ActionProvider";
import MessageParser from "./MessageParser";
import Chatbot, { createChatBotMessage } from "react-chatbot-kit";
import { useParams } from "react-router-dom";

function Bets() {
  const [clientSecret, setClientSecret] = useState("");
  const [betsLoaded, setBetsLoaded] = useState(false);
  const { id } = useParams()
  
  console.log('id',id)
  
  config.initialMessages = [
    createChatBotMessage(
      "Processing payment...",
      {
        widget: "processPayment",
        delay: 1000,
        loading: true
      }
    ),
  ]
  
  return (
    <div>
      <div style={{marginTop: 0}}>
        <Chatbot
          config={config}
          actionProvider={ActionProvider}
          messageParser={MessageParser}
        />
      </div>
    </div>
  )
}

export default Bets;
